import React, { useEffect, useRef } from "react";
import { io, Socket } from "socket.io-client";
import { config } from "../config";
import { useAuth } from "./AuthContext";

interface Props {
  children: React.ReactNode;
}

interface Values {
  socket: Socket;
}

const SocketContext = React.createContext<Values>(undefined!);

const SocketProvider = (props: Props) => {
  const { children } = props;
  const auth = useAuth();

  const socket = useRef<Socket>(
    io(config.apiUrl, {
      auth: {
        token: auth.accessToken,
      },
      autoConnect: false,
      transports: ["websocket"],
      forceNew: true,
    })
  );

  useEffect(() => {
    if (socket.current) {
      socket.current.auth = {
        token: auth.accessToken,
      };
    }
  }, [auth.accessToken]);

  useEffect(() => {
    if (auth.isAuthenticated) {
      const s = socket.current;
      s.connect();
    }
  }, []);

  return (
    <SocketContext.Provider
      value={{
        socket: socket.current,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

const useSocket = () => {
  const context = React.useContext(SocketContext);

  if (context === undefined) {
    throw new Error("useSocket must be used within a SocketProvider");
  }

  return context;
};

export { SocketProvider, useSocket };
