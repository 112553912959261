import { styled } from "@mui/material/styles";
import Form from "./form";
import Logo from "../.././logo.svg";

export interface AuthResponse {
  accessToken: String;
  refreshToken: String;
}

function Login() {
  //   const [authResponse, setAuthResponse] = useState<AuthResponse>();
  return (
    <Container>
      <FormContainer>
        <img src={Logo} />
        <Form />
      </FormContainer>
    </Container>
  );
}

const Container = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  padding: "32px",
});
const FormContainer = styled("div")({
  width: "100%",
  minWidth: "300px",
  maxWidth: "500px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
});

export default Login;
