import {styled} from "@mui/material/styles";
import {useSwitchboard} from "../../SwitchboardProvider";

function History() {
  const { historylog } = useSwitchboard();

  return (
    <Container>
      <BacklogContainer>
        <BacklogTitle>History</BacklogTitle>
        {historylog.map((user, key) => {
          return (
            <CardContainer key={key}>
              <p style={{ color: "white" }}>{`@${user.displayname}, ${user.id}`}</p>
              <p style={{ color: "white" }}>{user.backlogJoinTime}</p>
              {/* <p style={{ color: "white" }}>{`${DateTime.fromMillis(user.backlogJoinTime).toLocaleString({
                weekday: "short",
                month: "short",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              })} `}</p> */}
            </CardContainer>
          );
        })}
      </BacklogContainer>
    </Container>
  );
}

const BacklogContainer = styled("div")({
  background: "linear-gradient(0deg, rgba(208, 188, 255, 0.05), rgba(208, 188, 255, 0.05)), #1C1B1F",
  boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3)",
  borderRadius: "16px",
  padding: "40px",
  width: "100%",
  // minWidth: "400px",
});

const BacklogTitle = styled("h2")({
  fontFamily: "'Roboto'",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "24px",
  lineHeight: "32px",
  color: "#FFFFFF",
  margin: 0,
  marginBottom: 16,
});
const CardContainer = styled("div")({
  borderRadius: "12px",
  background: "linear-gradient(0deg, rgba(208, 188, 255, 0.14), rgba(208, 188, 255, 0.14)), #1C1B1F",
  border: "1px solid #49454F",
  display: "flex",
  flexDirection: "column",
  height: "100px",
  justifyContent: "center",
  padding: "16px",
});

const Container = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexDirection: "row-reverse",
  gap: "32px",
  padding: "32px",
  flexWrap: "wrap",
});

export default History;
