import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthContext } from "./contexts/AuthContext";
import Routes from "./routes/Routes";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#D0BCFF",
    },
    secondary: {
      main: "#CCC2DC",
    },
    error: {
      main: "#F2B8B5",
    },
  },
});

function App() {
  return (
    <div>
      <AuthContext>
        <ThemeProvider theme={darkTheme}>
          <Router>
            <Routes />
          </Router>
        </ThemeProvider>
      </AuthContext>
    </div>
  );
}

const Container = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexDirection: "row-reverse",
  gap: "32px",
  padding: "32px",
  flexWrap: "wrap",
});

export default App;
