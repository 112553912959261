import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Switch from "@mui/material/Switch";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { useEffect, useState } from "react";
import Logo from "../.././logo.svg";
import { useAuth } from "../../contexts/AuthContext";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import HistoryIcon from "@mui/icons-material/History";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { useSocket } from "../../contexts/SocketContext";

export default function EnableColorOnDarkAppBar() {
  const [state, setState] = React.useState(false);
  const [systemOn, setSystemOn] = React.useState(false);
  const [avgHoldTime, SetAvgHoldTime] = useState<String>("");
  const { logout } = useAuth();
  let navigate = useNavigate();
  const { socket } = useSocket();

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setState(!state);
  };

  const handleToggle = () => {
    setSystemOn((prevState) => !prevState);
    const body = { key: "systemOn", value: !systemOn };
    socket.emit("setSingleKeyValue", body);
  };

  const list = () => (
    <Box
      sx={{ width: 250, display: "flex", flexDirection: "column", height: "100%", justifyContent: "space-between" }}
      role="presentation"
    >
      <List>
        <ListItem key={1}>
          <ListItemIcon>
            <PowerSettingsNewIcon color={systemOn ? "primary" : "error"} />
          </ListItemIcon>
          <ListItemText id="switch-list-label-wifi" primary={`Power`} />
          <Switch
            edge="end"
            onChange={() => handleToggle()}
            checked={systemOn}
            inputProps={{
              "aria-labelledby": "switch-list-label-wifi",
            }}
          />
        </ListItem>
        <Divider />
      </List>
      <Box
        sx={{ width: 250, display: "flex", flexDirection: "column", height: "100%", justifyContent: "space-between" }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List>
          <ListItem key={1} disablePadding>
            <ListItemButton onClick={() => navigate("/switchboard")}>
              <ListItemIcon>
                <ViewColumnIcon />
              </ListItemIcon>
              <ListItemText primary={"Switchboard"} />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem key={2} disablePadding>
            <ListItemButton onClick={() => navigate("/history")}>
              <ListItemIcon>
                <HistoryIcon />
              </ListItemIcon>
              <ListItemText primary={"History"} />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem key={3} disablePadding>
            <ListItemButton onClick={() => navigate("/settings")}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary={"Settings"} />
            </ListItemButton>
          </ListItem>
          <Divider />
        </List>
        <List>
          <Divider />
          <ListItem key={4} disablePadding>
            <ListItemButton onClick={() => logout()}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={"Logout"} />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Box>
  );

  useEffect(() => {
    socket.on("Connect", () => {
      console.log(`Conected on ${socket.id}`); // x8WIv7-mJelg7on_ALbx
    });
    socket.on("updateHoldTimeAverage", (data) => {
      SetAvgHoldTime(data);
    });
    socket.emit("getKeyValue", "systemOn");
    socket.on("systemOn", (data) => {
      setSystemOn(Boolean(Number(data)));
    });

    return () => {
      socket.off("UpdateHoldTimeAverage");
      socket.off("systemOn");
      socket.off("Connect", () => {
        console.log(`Socket OFF on ${socket.id}`); // x8WIv7-mJelg7on_ALbx
      });
      // socket.emit("fetch");
    };
  }, []);
  return (
    <div>
      <div>
        <SwipeableDrawer open={state} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
          {list()}
        </SwipeableDrawer>
        <Stack spacing={2} sx={{ flexGrow: 1 }}>
          <AppBar position="static" color="primary">
            <Toolbar>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={toggleDrawer(true)}
                >
                  <MenuIcon />
                </IconButton>

                <img src={Logo} alt="" onClick={() => navigate("/switchboard")} style={{ cursor: "pointer" }} />
                <p>Avg Hold: {avgHoldTime}</p>
              </div>
            </Toolbar>
          </AppBar>
        </Stack>
      </div>
    </div>
  );
}
