import React, { useEffect, useState } from "react";
import {useSocket} from "./contexts/SocketContext";

interface Props {
  children: React.ReactNode;
}

export interface User {
  id: string;
  displayAvatarURL: string;
  displayname: string;
  backlogJoinTime: number;
  currentChannel: string;
  position: number;
  name?: string;
  topic?: string;
}

export enum SocketMessageTypes {
  UpdateBacklog = "updateBacklog",
  UpdateHoldlog = "updateHoldlog",
  UpdateLivelog = "updateLivelog",
  UpdateHoldTimeAverage = "updateHoldTimeAverage",
  Connect = "connect",
  History = "history",
  Fetch = "fetch",
}

interface Values {
  backlog: User[];
  holdlog: User[];
  livelog: User[];
  historylog: User[];
  avgHoldTime: string;
}

const SwitchboardContext = React.createContext<Values>(undefined!);

const SwitchboardProvider = (props: Props) => {
  const { children } = props;
  const { socket } = useSocket();

  const [avgHoldTime, SetAvgHoldTime] = useState<string>("");
  const [backlog, setBacklog] = useState<User[]>([]);
  const [holdlog, setHoldlog] = useState<User[]>([]);
  const [livelog, setLivelog] = useState<User[]>([]);
  const [historylog, setHistorylog] = useState<User[]>([]);

  useEffect(() => {
    const handleConnect = () => {
      console.log("Connected");
    };

    socket.on(SocketMessageTypes.Connect, handleConnect);

    return () => {
      socket.off(SocketMessageTypes.Connect);
    };
  }, []);

  useEffect(() => {
    const handleSetBacklog = (users: User[]) => {
      setBacklog(users);
    };

    socket.on(SocketMessageTypes.UpdateBacklog, (data: User[]) => {
      handleSetBacklog(data);
    });

    return () => {
      socket.off(SocketMessageTypes.UpdateBacklog, handleSetBacklog);
    };
  }, []);

  useEffect(() => {
    const handleSetHoldlog = (users: User[]) => {
      setHoldlog(users);
    };

    socket.on(SocketMessageTypes.UpdateHoldlog, (data: User[]) => {
      handleSetHoldlog(data);
    });

    return () => {
      socket.off(SocketMessageTypes.UpdateHoldlog, handleSetHoldlog);
    };
  }, []);

  useEffect(() => {
    const handleSetLivelog = (users: User[]) => {
      setLivelog(users);
    };

    socket.on(SocketMessageTypes.UpdateLivelog, (data: User[]) => {
      handleSetLivelog(data);
    });

    return () => {
      socket.off(SocketMessageTypes.UpdateLivelog, handleSetLivelog);
    };
  }, []);

  useEffect(() => {
    const handleSetHistory = (users: User[]) => {
      setHistorylog(users);
    };

    socket.on(SocketMessageTypes.History, (data: User[]) => {
      handleSetHistory(data);
    });

    return () => {
      socket.off(SocketMessageTypes.History, handleSetHistory);
    };
  }, []);

  useEffect(() => {
    const handleAvgHoldTime = (avgHoldTime: string) => {
      SetAvgHoldTime(avgHoldTime);
    };

    socket.on(SocketMessageTypes.UpdateHoldTimeAverage, handleAvgHoldTime);

    return () => {
      socket.off(SocketMessageTypes.UpdateHoldTimeAverage, handleAvgHoldTime);
    };
  }, []);

  return (
    <SwitchboardContext.Provider
      value={{
        backlog,
        holdlog,
        livelog,
        historylog,
        avgHoldTime,
      }}
    >
      {children}
    </SwitchboardContext.Provider>
  );
};

const useSwitchboard = () => {
  const context = React.useContext(SwitchboardContext);

  if (context === undefined) {
    throw new Error("useSwitchboard must be used within a SwitchboardProvider");
  }

  return context;
};

export { SwitchboardProvider, useSwitchboard };
