import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import Form from "./form";
import {useSocket} from "../../contexts/SocketContext";

interface IFormInputs {
  key: String;
  value: String;
  stagingChannelId: String;
  liveChannelId: String;
  requestRoleId: String;
  hostUserId?: String;
  screenerUserId?: String;
  botClientId: String;
  requestChannelInviteUrl: String;
}

function Settings() {
  const [settings, setSettings] = useState<IFormInputs>();
  const { socket } = useSocket();

  const these = [
    "guildId",
    "requestChannelId",
    "stagingChannelId",
    "liveChannelId",
    "requestRoleId",
    "hostUserId",
    "screenerUserId",
    "botClientId",
    "requestChannelInviteUrl",
  ];

  useEffect(() => {
    socket.on("test", (data) => {
      const newSettings = data.reduce(
        (a: {}, b: { key: string; value: string }) => ({
          ...a,
          [b.key]: b.value,
        }),
        {}
      ) as { [key: string]: string };

      setSettings(newSettings as unknown as IFormInputs);
    });
    socket.emit("fetchMultipleKeyValues", these);
  }, []);

  if (!settings) {
    return <p>Fuck</p>;
  }
  return (
    <Container>
      <Form settings={settings} />
    </Container>
  );
}

const Container = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: "32px",
  flexWrap: "wrap",
});

export default Settings;
