import { Route, Routes } from "react-router-dom";
import React from "react";
import Login from "../pages/login";

const PublicRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />}/>
        <Route path="/" element={<Login />}/>
        <Route path="*" element={<Login />}/>
      </Routes>
    </>
  );
};

export default PublicRoutes;
