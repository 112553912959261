import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface Props {
  open: boolean;
  bucket: string;
  handleClose: (bucket: string) => void;
  handleAccept: (bucket: string) => void;
}

export default function AlertDialog(props: Props) {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => props.handleClose(props.bucket)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{`Remove all of the users in the ${props.bucket} channel?`}</DialogTitle>
        <DialogContent>
          <DialogContentText>This cannot be undone.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size={"large"} color={"inherit"} onClick={() => props.handleClose(props.bucket)}>
            Cancel
          </Button>
          <Button size={"large"} color={"error"} variant="contained" onClick={() => props.handleAccept(props.bucket)}>
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
