import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Button } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { useState, forwardRef } from "react";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import {useSocket} from "../../contexts/SocketContext";

interface IFormInputs {
  guildId: String;
  requestChannelId: String;
  stagingChannelId: String;
  liveChannelId: String;
  requestRoleId: String;
  hostUserId?: String;
  screenerUserId?: String;
  botClientId: String;
  requestChannelInviteUrl: String;
}

interface Props {
  settings: any;
}

function Form(props: Props) {
  const { handleSubmit, control, reset } = useForm<IFormInputs>();
  const onSubmit: SubmitHandler<IFormInputs> = (data) => handleSubmitPress(data);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const { socket } = useSocket();

  const handleSubmitPress = (data: IFormInputs) => {
    socket.emit("setAllKeyValues", data);
    setSnackBarOpen(true);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  };

  const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <Container>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Settings sucessfully saved!
        </Alert>
      </Snackbar>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <h1 style={{ color: "white" }}>Settings</h1>
          <Button size="large" variant="contained" type="submit" style={{ height: "44px" }}>
            Save
          </Button>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "24px", justifyContent: "center" }}>
          <BacklogContainer>
            <div
              style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
            ></div>
            <h3 style={{ color: "white" }}>Channels</h3>
            <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
              <Controller
                name="requestChannelId"
                control={control}
                defaultValue={props.settings.requestChannelId}
                rules={{ required: true, minLength: 18, maxLength: 20 }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <InputLabel error={error !== undefined}>Request Voice Channel ID</InputLabel>
                    <TextField
                      {...field}
                      fullWidth
                      error={error !== undefined}
                      helperText={error ? "This fucker is 18 digits" : ""}
                      type="number"
                    />
                  </>
                )}
              />
              <Controller
                name="stagingChannelId"
                control={control}
                defaultValue={props.settings.stagingChannelId}
                rules={{ required: true, minLength: 18, maxLength: 20 }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <InputLabel error={error !== undefined}>Staging Voice Channel ID</InputLabel>
                    <TextField
                      {...field}
                      fullWidth
                      error={error !== undefined}
                      helperText={error ? "This fucker is 18 digits" : ""}
                      type="number"
                    />
                  </>
                )}
              />
              <Controller
                name="liveChannelId"
                control={control}
                defaultValue={props.settings.liveChannelId}
                rules={{ required: true, minLength: 18, maxLength: 20 }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <InputLabel error={error !== undefined}>Live Voice Channel ID</InputLabel>
                    <TextField
                      {...field}
                      fullWidth
                      error={error !== undefined}
                      helperText={error ? "This fucker is 18 digits" : ""}
                      type="number"
                    />
                  </>
                )}
              />
            </div>
          </BacklogContainer>
          <BacklogContainer>
            <h3 style={{ color: "white" }}>Roles</h3>
            <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
              <Controller
                name="requestRoleId"
                control={control}
                defaultValue={props.settings.requestRoleId}
                rules={{ required: true, minLength: 18, maxLength: 20 }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <InputLabel error={error !== undefined}>Request Role ID</InputLabel>
                    <TextField
                      {...field}
                      fullWidth
                      error={error !== undefined}
                      helperText={error ? "This fucker is 18 digits" : ""}
                      type="number"
                    />
                  </>
                )}
              />
              <h3 style={{ color: "white" }}>Url</h3>
              <Controller
                name="requestChannelInviteUrl"
                control={control}
                defaultValue={props.settings.requestChannelInviteUrl}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <InputLabel error={error !== undefined}>Request Channel Invite Url</InputLabel>
                    <TextField
                      {...field}
                      fullWidth
                      error={error !== undefined}
                      helperText={error ? "This fucker is 18 digits" : ""}
                      type="text"
                    />
                  </>
                )}
              />
            </div>
          </BacklogContainer>
          <BacklogContainer>
            <h3 style={{ color: "white" }}>Users</h3>
            <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
              <Controller
                name="guildId"
                control={control}
                defaultValue={props.settings.guildId}
                rules={{ required: true, minLength: 18, maxLength: 20 }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <InputLabel error={error !== undefined}>Guild ID</InputLabel>
                    <TextField
                      {...field}
                      fullWidth
                      error={error !== undefined}
                      helperText={error ? "This fucker is 18 digits" : ""}
                      type="number"
                    />
                  </>
                )}
              />
              <Controller
                name="hostUserId"
                control={control}
                rules={{ required: false, minLength: 18, maxLength: 20 }}
                defaultValue={props.settings.hostUserId}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <InputLabel error={error !== undefined}>Host User ID</InputLabel>
                    <TextField
                      {...field}
                      fullWidth
                      error={error !== undefined}
                      helperText={error ? "This fucker is 18 digits" : ""}
                      type="number"
                    />
                  </>
                )}
              />
              <Controller
                name="screenerUserId"
                control={control}
                rules={{ required: false, minLength: 18, maxLength: 20 }}
                defaultValue={props.settings.screenerUserId}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <InputLabel error={error !== undefined}>Screener User ID</InputLabel>
                    <TextField
                      {...field}
                      fullWidth
                      error={error !== undefined}
                      helperText={error ? "This fucker is 18 digits" : ""}
                      type="number"
                    />
                  </>
                )}
              />
              <Controller
                name="botClientId"
                control={control}
                rules={{ required: true, minLength: 18, maxLength: 20 }}
                defaultValue={props.settings.botClientId}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <InputLabel error={error !== undefined}>Bot User ID</InputLabel>
                    <TextField
                      {...field}
                      fullWidth
                      error={error !== undefined}
                      helperText={error ? "This fucker is 18 digits" : ""}
                      type="number"
                    />
                  </>
                )}
              />
            </div>
          </BacklogContainer>
        </div>
      </form>
    </Container>
  );
}

const Container = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: "32px",
  flexWrap: "wrap",
});

const BacklogContainer = styled("div")({
  background: "linear-gradient(0deg, rgba(208, 188, 255, 0.05), rgba(208, 188, 255, 0.05)), #1C1B1F",
  boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3)",
  borderRadius: "16px",
  padding: "40px",
  width: "25%",
  minWidth: "400px",
  gap: "24px",
});

export default Form;
