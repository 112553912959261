import { Route, Routes } from "react-router-dom";
import History from "../pages/history";
import Settings from "../pages/settings";
import React from "react";
import Switchboard from "../pages/switchboard";
import Appbar from "../components/AppBar";
import { SocketProvider } from "../contexts/SocketContext";
import { SwitchboardProvider } from "../SwitchboardProvider";
import Login from "../pages/login";

const SecureRoutes = () => {
  return (
    <>
      <SocketProvider>
        <SwitchboardProvider>
          <Appbar />
          <Routes>
            <Route path="/history" element={<History />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/switchboard" element={<Switchboard />} />
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </SwitchboardProvider>
      </SocketProvider>
    </>
  );
};

export default SecureRoutes;
