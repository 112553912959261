import { useAuth } from "../contexts/AuthContext";
import PublicRoutes from "./PublicRoutes";
import SecureRoutes from "./SecureRoutes";

const Routes = () => {
    const { isAuthenticated } = useAuth();

    return (isAuthenticated ? <SecureRoutes /> : <PublicRoutes />)
}

export default Routes;