import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Stack, styled } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { User } from "../../pages/switchboard";
import {useSocket} from "../../contexts/SocketContext";

interface Props {
  user: User;
  position: number;
  bucket: string;
  updateBucket: (user: User, bucket: string) => void;
}

const renderActionButton = (props: Props, hostUserId: string, screenerUserId: string) => {
  switch (props.bucket) {
    case "backlog":
      if (props.user.id !== hostUserId && props.user.id !== screenerUserId) {
        return (
          <Button size={"small"} variant="outlined" onClick={() => props.updateBucket(props.user, props.bucket)}>
            Move to Stage
          </Button>
        );
      }
      return;
    case "hold":
      if (props.user.id !== hostUserId && props.user.id !== screenerUserId) {
        return (
          <Button size={"small"} variant="outlined" onClick={() => props.updateBucket(props.user, props.bucket)}>
            Move to Live
          </Button>
        );
      }
      return;
    case "live":
      if (props.user.id !== hostUserId && props.user.id !== screenerUserId) {
        return (
          <Button
            size="small"
            variant="outlined"
            color="error"
            onClick={() => props.updateBucket(props.user, props.bucket)}
          >
            End Call
          </Button>
        );
      }
      return;
    default:
      console.log("No such bucket exists!");
      break;
  }
};

const truncateString = (str: string, num: number) => {
  if (str) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  }
};

export default function MediaCard(props: Props) {
  const [deltaTime, setDeltaTime] = useState<string>("...");
  const [hostUserId, setHostUserId] = useState<string>("");
  const [screenerUserId, setScreenerUserId] = useState<string>("");

  const { socket } = useSocket();
  const kickUser = (id: string) => {
    socket.emit("kickUser", id);
  };

  const renderDeleteButton = (props: Props, hostUserId: string, screenerUserId: string) => {
    if (props.user.id !== hostUserId && props.user.id !== screenerUserId) {
      return (
        <Button
          size={"small"}
          color={"secondary"}
          // style={{
          //   backgroundColor: "#4A4458",
          //   color: "#381E72",
          // }}
          variant="outlined"
          onClick={() => kickUser(props.user.id)}
        >
          <DeleteIcon sx={{ color: "#D0BCFF" }} />
        </Button>
      );
    }
  };

  const calculateTime = (epoch: number) => {
    const currentTime = DateTime.now();
    const joinTime = DateTime.fromMillis(epoch);
    const diff = currentTime.diff(joinTime).toFormat("h:mm:ss");
    setDeltaTime(diff);
  };

  useEffect(() => {
    const id = setInterval(() => {
      calculateTime(props.user.backlogJoinTime);
    }, 1000);
    return () => {
      clearInterval(id);
    };
  });

  useEffect(() => {
    socket.emit("getKeyValue", "hostUserId");
    socket.emit("getKeyValue", "screenerUserId");
    socket.on("hostUserId", (data) => {
      setHostUserId(data);
    });
    socket.on("screenerUserId", (data) => {
      setScreenerUserId(data);
    });
    return () => {
      socket.off("hostUserId");
      socket.off("screenerUserId");
      socket.off("getKeyValue");
    };
  }, []);

  return (
    <CardContainer>
      <InnerCardRowContainer>
        <PositionContainer>
          <PositionNumber>{props.position}</PositionNumber>
          <TimeBodyText>{deltaTime}</TimeBodyText>
        </PositionContainer>
        <CardContentColumnContainer>
          <InnerCardContentRowContainer>
            <LeftSide>
              <Tooltip title={`@${props.user.displayname}`}>
                <img
                  src={props.user.displayAvatarURL}
                  alt=""
                  style={{ width: "80px", height: "80px", borderRadius: "100px" }}
                ></img>
              </Tooltip>
              <BodyTextContainer>
                <NameBodyText>{truncateString(props?.user?.name || "", 16)}</NameBodyText>
                <TimeBodyText>{truncateString(props?.user?.topic || "", 60)}</TimeBodyText>
                <Stack spacing={2} direction="row">
                  {renderActionButton(props, hostUserId, screenerUserId)}
                  {props.bucket !== "live" ? renderDeleteButton(props, hostUserId, screenerUserId) : null}
                </Stack>
              </BodyTextContainer>
            </LeftSide>
          </InnerCardContentRowContainer>
        </CardContentColumnContainer>
      </InnerCardRowContainer>
    </CardContainer>
  );
}

const InnerCardContentRowContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  gap: "24px",
  justifyContent: "space-between",
});

const InnerCardRowContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  gap: "16px",
  width: "100%",
});

const CardContentColumnContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
});

const LeftSide = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  gap: "16px",
  alignItems: "center",
  marginRight: "16px",
});
const BodyTextContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
});
const PositionContainer = styled("div")({
  width: "80px",
  height: "100%",
  backgroundColor: "black",
  borderWidth: "1px 1px 1px 0px",
  borderStyle: "solid",
  borderColor: "#49454F",
  borderRadius: "12px 0px 0px 12px",
  background: "linear-gradient(0deg, rgba(208, 188, 255, 0.11), rgba(208, 188, 255, 0.11)), #1C1B1F;",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
});
const PositionNumber = styled("h2")({
  margin: 0,
  fontFamily:"Roboto,Helvetica,Arial,sans-serif",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "36px",
  lineHeight: "44px",
  textAlign: "center",
  color: "#EADDFF",
});
const TimeBodyText = styled("span")({
  fontFamily:"Roboto,Helvetica,Arial,sans-serif",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "12px",
  lineHeight: "16px",
  letterSpacing: "0.4px",
  color: "#fff",
});
const NameBodyText = styled("span")({
  fontFamily:"Roboto,Helvetica,Arial,sans-serif",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "20px",
  letterSpacing: "0.25px",
  color: "#fff",
});
const CardContainer = styled("div")({
  borderRadius: "12px",
  background: "linear-gradient(0deg, rgba(208, 188, 255, 0.14), rgba(208, 188, 255, 0.14)), #1C1B1F",
  border: "1px solid #49454F",
  display: "flex",
  flexDirection: "row",
  height: "160px",
  cursor: "default",
});
