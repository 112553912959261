import jwtDecode, { JwtPayload } from "jwt-decode";
import React, { useEffect, useRef, useState } from "react";
import useInterval from "../components/hooks/useInterval";
import { useLocalStorage } from "../components/hooks/useLocalStorage";
import { config } from "../config";

interface Values {
  accessToken?: string;
  isAuthenticated?: boolean;
  login: (username: string, password: string) => void;
  logout: () => void;
}

const AuthStateContext = React.createContext<Values>(undefined!);

interface Tokens {
  accessToken: string;
  refreshToken: string;
}

interface Props {
  children: React.ReactNode;
}

function isTokenExpired(token: string) {
  const decodedAccessToken = jwtDecode<JwtPayload>(token);
  if (decodedAccessToken.exp) {
    return decodedAccessToken.exp * 1000 - Date.now() <= 0;
  }
}

export const AuthContext = (props: Props) => {
  const { children } = props;
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const [accessToken, setAccessToken] = useLocalStorage<string | undefined>("ACCESS_TOKEN", undefined);
  const [refreshToken, setRefreshToken] = useLocalStorage<string | undefined>("REFRESH_TOKEN", undefined);
  const refreshInProgress = useRef(false);

  const refreshTokens = async () => {
    if (accessToken && refreshToken) {
      const accessTokenExpired = isTokenExpired(accessToken);
      const refreshTokenExpired = isTokenExpired(refreshToken);
      const shouldAttemptRefresh = accessTokenExpired && !refreshTokenExpired && !refreshInProgress.current;
      if (shouldAttemptRefresh) {
        refreshInProgress.current = true;
        const response = await fetch(`${config.apiUrl}/refresh`, {
          method: "POST",
          mode: "cors", // no-cors, *cors, same-origin
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ refreshToken }),
        });
        const { accessToken }: { accessToken: string } = await response.json();
        setAccessToken(accessToken);
        refreshInProgress.current = false;
      } else if (accessTokenExpired && refreshTokenExpired) {
        setIsAuthenticated(false);
      }
    }
  };

  useInterval(async () => {
    refreshTokens();
  }, 5000);

  const login = async (username: string, password: string) => {
    const payload = {
      username,
      password,
    };

    const response = await fetch(`${config.apiUrl}/login`, {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });

    const tokens: Tokens = await response.json();
    setAccessToken(tokens.accessToken);
    setRefreshToken(tokens.refreshToken);
  };

  const logout = async () => {
    window.localStorage.clear();
    setIsAuthenticated(false);
    setAccessToken(undefined);
    setRefreshToken(undefined);
  };

  useEffect(() => {
    if (accessToken) {
      const isExpired = isTokenExpired(accessToken);
      if (!isExpired) {
        setIsAuthenticated(true);
      }
    }
  }, [accessToken]);

  refreshTokens();

  return (
    <AuthStateContext.Provider
      value={{
        isAuthenticated,
        accessToken,
        login,
        logout,
      }}
    >
      {children}
    </AuthStateContext.Provider>
  );
};

export const useAuth = () => {
  const context = React.useContext(AuthStateContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthContext");
  }

  return context;
};
