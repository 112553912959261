import { Button } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useAuth } from "../../contexts/AuthContext";

interface IFormInputs {
  username: string;
  password: string;
}

function Form() {
  const { handleSubmit, control } = useForm<IFormInputs>();
  const { login } = useAuth();

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    await login(data.username, data.password);
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3 style={{ color: "white" }}>Login</h3>
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Controller
            name="username"
            control={control}
            // defaultValue={props.settings.requestChannelId}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <>
                <InputLabel error={error !== undefined}>Username</InputLabel>
                <TextField
                  {...field}
                  fullWidth
                  error={error !== undefined}
                  helperText={error ? "This field is required." : ""}
                  type="email"
                />
              </>
            )}
          />
          <Controller
            name="password"
            control={control}
            // defaultValue={props.settings.stagingChannelId}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <>
                <InputLabel error={error !== undefined}>Password</InputLabel>
                <TextField
                  {...field}
                  fullWidth
                  error={error !== undefined}
                  helperText={error ? "This field is required." : ""}
                  type="password"
                />
              </>
            )}
          />
        </div>
        <Button fullWidth size="large" variant="contained" type="submit" style={{ height: "44px", marginTop: "48px" }}>
          Login
        </Button>
      </form>
    </Container>
  );
}

const Container = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: "32px",
  flexWrap: "wrap",
  width: "100%",
});

const BacklogContainer = styled("div")({
  background: "linear-gradient(0deg, rgba(208, 188, 255, 0.05), rgba(208, 188, 255, 0.05)), #1C1B1F",
  boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3)",
  borderRadius: "16px",
  padding: "40px",
  width: "25%",
  minWidth: "400px",
  gap: "24px",
});

export default Form;
