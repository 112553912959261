import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import {styled} from "@mui/material/styles";
import {useEffect, useState} from "react";
import MemberCard from "../../components/MemberCard";
import {SocketMessageTypes, useSwitchboard} from "../../SwitchboardProvider";
import {Button} from "@mui/material";
import Dialog from "../../components/Dialog";
import {useSocket} from "../../contexts/SocketContext";

export interface User {
  id: string;
  displayAvatarURL: string;
  displayname: string;
  backlogJoinTime: number;
  currentChannel: string;
  position: number;
  name?: string;
  topic?: string;
}

enum BucketTypes {
  Backlog = "backlog",
  Holdlog = "hold",
  Livelog = "live",
}

function Switchboard() {
  const { backlog, holdlog, livelog } = useSwitchboard();
  const [backlogDialogOpen, setBacklogDialogOpen] = useState(false);
  const [stagingDialogOpen, setStagingDialogOpen] = useState(false);
  const [liveDialogOpen, setLiveDialogOpen] = useState(false);
  const { socket } = useSocket();

  const handleClose = (bucket: string) => {
    switch (bucket) {
      case BucketTypes.Backlog:
        setBacklogDialogOpen(false);
        break;
      case BucketTypes.Holdlog:
        setStagingDialogOpen(false);
        break;
      case BucketTypes.Livelog:
        setLiveDialogOpen(false);
        break;
    }
  };

  const handleAccept = (bucket: string) => {
    socket.emit("clearAllUsersInChannel", bucket);
    console.log("done");
    handleClose(bucket);
  };

  useEffect(() => {
    socket.emit(SocketMessageTypes.Fetch);
  }, []);

  const updateBucket = (user: User, bucket: string): void => {
    switch (bucket) {
      case BucketTypes.Backlog:
        socket.emit("move", { id: user.id, room: bucket });
        break;
      case BucketTypes.Holdlog:
        socket.emit("move", { id: user.id, room: bucket });
        break;
      case BucketTypes.Livelog:
        socket.emit("move", { id: user.id, room: bucket });
        break;
      default:
        console.log("No such bucket exists!");
        break;
    }
  };

  return (
    <div>
      <Container>
        <Dialog
          open={backlogDialogOpen}
          handleClose={handleClose}
          bucket={BucketTypes.Backlog}
          handleAccept={handleAccept}
        />
        <BacklogContainer>
          <TitleContainer>
            <Badge badgeContent={livelog.length} color="primary">
              <BacklogTitle>Live</BacklogTitle>
            </Badge>
            <Button color="error" size="small" variant="contained" onClick={() => setLiveDialogOpen(true)}>
              Clear all
            </Button>
          </TitleContainer>
          <Stack spacing={2}>
            {livelog.map((user, key) => {
              return (
                <MemberCard
                  key={key}
                  user={user}
                  position={user.position}
                  bucket={BucketTypes.Livelog}
                  updateBucket={updateBucket}
                />
              );
            })}
          </Stack>
        </BacklogContainer>
        <BacklogContainer>
          <Dialog
            open={stagingDialogOpen}
            handleClose={handleClose}
            bucket={BucketTypes.Holdlog}
            handleAccept={handleAccept}
          />
          <TitleContainer>
            <Badge badgeContent={holdlog.length} color="primary">
              <BacklogTitle>Staging</BacklogTitle>
            </Badge>
            <Button color="error" size="small" variant="contained" onClick={() => setStagingDialogOpen(true)}>
              Clear all
            </Button>
          </TitleContainer>
          <Stack spacing={2}>
            {holdlog.map((user, key) => {
              return (
                <MemberCard
                  key={key}
                  user={user}
                  position={user.position + livelog.length}
                  bucket={BucketTypes.Holdlog}
                  updateBucket={updateBucket}
                />
              );
            })}
          </Stack>
        </BacklogContainer>
        <BacklogContainer>
          <Dialog
            open={liveDialogOpen}
            handleClose={handleClose}
            bucket={BucketTypes.Livelog}
            handleAccept={handleAccept}
          />
          <TitleContainer>
            <Badge badgeContent={backlog.length} color="primary">
              <BacklogTitle>Backlog</BacklogTitle>
            </Badge>
            <Button color="error" size="small" variant="contained" onClick={() => setBacklogDialogOpen(true)}>
              Clear all
            </Button>
          </TitleContainer>
          <Stack spacing={2}>
            {backlog.map((user, key) => {
              return (
                <MemberCard
                  key={key}
                  user={user}
                  position={user.position + holdlog.length + livelog.length}
                  bucket={BucketTypes.Backlog}
                  updateBucket={updateBucket}
                />
              );
            })}
          </Stack>
        </BacklogContainer>
      </Container>
    </div>
  );
}
const BacklogContainer = styled("div")({
  background: "linear-gradient(0deg, rgba(208, 188, 255, 0.05), rgba(208, 188, 255, 0.05)), #1C1B1F",
  boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3)",
  borderRadius: "16px",
  padding: "40px",
  width: "25%",
  minWidth: "400px",
});

const TitleContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "16px",
});
const BacklogTitle = styled("h2")({
  fontFamily:"Roboto,Helvetica,Arial,sans-serif",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "24px",
  lineHeight: "32px",
  color: "#FFFFFF",
  margin: 0,
});
const CardContainer = styled("div")({
  borderRadius: "12px",
  background: "linear-gradient(0deg, rgba(208, 188, 255, 0.14), rgba(208, 188, 255, 0.14)), #1C1B1F",
  border: "1px solid #49454F",
  display: "flex",
  flexDirection: "column",
  height: "100px",
  justifyContent: "center",
  padding: "16px",
});

const Container = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexDirection: "row-reverse",
  gap: "32px",
  padding: "32px",
  flexWrap: "wrap",
});

export default Switchboard;
